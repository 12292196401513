<template>
  <v-container>
    <v-data-table
      dense
      :headers="headers"
      :items="results"
      item-key="name"
      class="elevation-1"
    ></v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: 'Results',
  data: () => ({
    headers: [
      {
        text: 'Startnummer',
        align: 'start',
        value: 'r_start_position',
      },
      { text: 'Rallytid (s)', value: 'r_race_time' },
      { text: 'Lunchtid (s)', value: 'r_lunch_time' },
      { text: 'Tidsavdrag (s)', value: 'r_time_reduction' },
      { text: 'Tidspålägg (s)', value: 'r_time_addition' },
      { text: 'Totaltid (s)', value: 'r_result' },
      { text: 'Placering', value: 'r_placement' },
    ],
    results: []
  }),
  created () {
    this.initialize()
  },
  methods: {
    ...mapActions(["getAllResults"]),
    async initialize () {
      const results = await this.getAllResults()

      this.results = results
    }
  }
}
</script>

<style>

</style>